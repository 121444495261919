import pathUrl from "./path"
import instance from './request'


const axios = instance;
const api = {
    // upload data =========================================================================
    getTemplateFile() {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-template-file");
    },

    // data page =========================================================================
    getOrganoidProjectInfo(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getOrganoidProjectInfo/" + id);
    },
    getPDXProjectInfo(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getPDXProjectInfo/" + id);
    },
    getExpressionOptions(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getExpressionOptions/" + id);
    },
    getDepOfProject(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getDepOfProject/" + id);
    },
    postGroupsPlotData(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/postGroupsPlotData",
        });
    },
    postProteinPlotData(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/postProteinPlotData",
        });
    },


    getProteinInfo(id){
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getProteinInfo/" + id);
    },
    getProteinProject(id){
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getProteinProject/" + id);
    },
    getExpTissuePlotData(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getExpTissuePlotData/" + id);
    },
    getPPIPlotData(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getPPIPlotData/" + id);
    },
    getNoPvaluePlot0(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getNoPvaluePlot0/" + id);
    },
    getNoPvaluePlot1(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getNoPvaluePlot1/" + id);
    },
    // search page =========================================================================
    getSearchProjectList() {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getSearchProjectList");
    },
    getSearchProteinList() {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getSearchProteinList");
    },
    // 二级下拉
    postFamilyProteinList(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/postFamilyProteinList",
        });
    },
    postSequence(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/postSequence",
        });
    },
    getSequenceResult(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getSequenceResult/" + id);
    },
    // Search Result ==========================================================================
    postSearchProjectResult(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/postSearchProjectResult",
        });
    },
    postSearchOrganoidResult(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/postSearchOrganoidResult",
        });
    },
    postSearchPDXResult(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/postSearchPDXResult",
        });
    },
    postSearchProteinResult(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/postSearchProteinResult",
        });
    },








    // Contact Part=====================================================================
    postContactUs(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/contactus",
        });
    },

    // Browse Page ==========================================================================
    postBrowsePage(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/postBrowsePage",
        });
    },






    // // Search Form Page==========================================================================
    // // 一级下拉
    // postDiseaseClass(post_data) {
    //     return axios({
    //         method: "post",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: post_data,
    //         url: pathUrl.baseUrl + "/the_biomarker/api-disease-class-list",
    //     });
    // },
    // postDrugStatusList(post_data) {
    //     return axios({
    //         method: "post",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: post_data, //发送post请求，使用data关键字接收请求参数
    //         url: pathUrl.baseUrl + "/the_biomarker/api-drug-status-list",
    //     });
    // },
    // postBiomarkerTypeList(post_data) {
    //     return axios({
    //         method: "post",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: post_data, //发送post请求，使用data关键字接收请求参数
    //         url: pathUrl.baseUrl + "/the_biomarker/api-biomarker-type-list",
    //     });
    // },
    // getBiomarkerAdrTypeList() {
    //     return axios.get(pathUrl.baseUrl + "/the_biomarker/api-biomarker-adr-type-list");
    // },
    // getDrugAdrTypeList() {
    //     return axios.get(pathUrl.baseUrl + "/the_biomarker/api-drug-adr-type-list");
    // },
    // // 二级下拉
    // postDiseaseList(post_data) {
    //     return axios({
    //         method: "post",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: post_data,
    //         url: pathUrl.baseUrl + "/the_biomarker/api-disease-list",
    //     });
    // },
    // postDrugList(post_data) {
    //     return axios({
    //         method: "post",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: post_data,
    //         url: pathUrl.baseUrl + "/the_biomarker/api-drug-list",
    //     });
    // },
    // postBiomarkerList(post_data) {
    //     return axios({
    //         method: "post",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: post_data,
    //         url: pathUrl.baseUrl + "/the_biomarker/api-biomarker-list",
    //     });
    // },
    // getAdrBiomarkerList(id) {
    //     return axios.get(pathUrl.baseUrl + "/the_biomarker/api-adr-biomarker-list/" + id);
    // },
    // getAdrDrugList(id) {
    //     return axios.get(pathUrl.baseUrl + "/the_biomarker/api-adr-drug-list/" + id);
    // },
    // // 非二级联动下拉
    // postAllDrugList(post_data) {
    //     return axios({
    //         method: "post",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: post_data,
    //         url: pathUrl.baseUrl + "/the_biomarker/api-all-drug-list",
    //     });
    // },
    // getEndpointBiomarkerList() {
    //     return axios.get(pathUrl.baseUrl + "/the_biomarker/api-endpoint-biomarker-list");
    // },
    // getTargetEngagementDrugClassList() {
    //     return axios.get(pathUrl.baseUrl + "/the_biomarker/api-target-engagement-drug-class-list");
    // },

    // // Search Result ==========================================================================
    // postDrugSearch(post_data) {
    //     return axios({
    //         method: "post",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: post_data, //发送post请求，使用data关键字接收请求参数
    //         url: pathUrl.baseUrl + "/the_biomarker/api-drug-search",
    //     });
    // },
    // postDiseaseSearch(post_data) {
    //     return axios({
    //         method: "post",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: post_data, //发送post请求，使用data关键字接收请求参数
    //         url: pathUrl.baseUrl + "/the_biomarker/api-disease-search",
    //     });
    // },
    // postBiomarkerSearch(post_data) {
    //     return axios({
    //         method: "post",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: post_data, //发送post请求，使用data关键字接收请求参数
    //         url: pathUrl.baseUrl + "/the_biomarker/api-biomarker-search",
    //     });
    // },








    // getTestData() {
    //     return axios.get(pathUrl.baseUrl + "/the_biomarker/api-get-test-data");
    // },




















}

export default api;