import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'OrgXenomics: An Integrated Proteomic Knowledge Base for Patient-derived Organoid and Xenograft'
    }
  },
  {
    path: '/data/xenograft',
    name: 'data-pdx',
    component: () => import(/* webpackChunkName: "about" */ '../views/DataPDX.vue'),
    meta: {
      title: 'Xenograft Proteomic Project Information | OrgXenomics'
    }
  },
  {
    path: '/data/organoid',
    name: 'data-organoid',
    component: () => import(/* webpackChunkName: "about" */ '../views/DataOrganoid.vue'),
    meta: {
      title: 'Organoid Proteomic Project Information | OrgXenomics'
    }
  },
  {
    path: '/data/protein',
    name: 'data-protein',
    component: () => import(/* webpackChunkName: "about" */ '../views/DataProtein.vue'),
    meta: {
      title: 'Differentially Expressed Protein Information | OrgXenomics'
    }
  },

  {
    path: '/search-project-page',
    name: 'search-project-page',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchProjectPage.vue'),
    meta: {
      title: 'Search for Organoid/xenograft-based Proteomic Project | OrgXenomics'
    }
  },
  {
    path: '/search-protein-page',
    name: 'search-protein-page',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchProteinPage.vue'),
    meta: {
      title: 'Search for Proteins | OrgXenomics'
    }
  },

  {
    path: '/search-project',
    name: 'search-drug',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchProject.vue'),
    meta: {
      title: 'Organoid & Xenograft Proteomic Project Search Results | OrgXenomics'
    }
  },
  {
    path: '/search-organoid',
    name: 'search-organoid',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchOrganoid.vue'),
    meta: {
      title: 'Organoid Proteomic Project Search Results | OrgXenomics'
    }
  },
  {
    path: '/search-xenograft',
    name: 'search-pdx',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchPDX.vue'),
    meta: {
      title: 'Xenograft Proteomic Project Search Results | OrgXenomics'
    }
  },
  {
    path: '/search-protein',
    name: 'search-protein',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchProtein.vue'),
    meta: {
      title: 'Differentially Expressed Proteins (DEPs) Search Results | OrgXenomics'
    }
  },
  {
    path: '/protein-similarity',
    name: 'protein-similarity',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProteinSimilarity.vue'),
    meta: {
      title: 'Differentially Expressed Proteins (DEPs) Sequence Similarity Search Results | OrgXenomics'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue'),
    meta: {
      title: 'Contact US | OrgXenomics'
    }
  },





  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutPage.vue'),
  //   meta: {
  //     title: 'About | SDAC'
  //   }
  // },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "about" */ '../views/DownloadPage.vue'),
    meta: {
      title: 'Download Data | OrgXenomics'
    }
  },
  {
    path: '/download-manual',
    name: 'download-manual',
    component: () => import(/* webpackChunkName: "about" */ '../views/DownloadManual.vue'),
    meta: {
      title: 'Download Manual | OrgXenomics'
    }
  },


  {
    path: '/manual',
    name: 'manual',
    component: () => import(/* webpackChunkName: "about" */ '../views/ManualPage.vue'),
    meta: {
      title: 'Manual | OrgXenomics'
    }
  },  
  {
    path: '/browse',
    name: 'Browse',
    component: () => import(/* webpackChunkName: "about" */ '../views/BrowsePage.vue'),
    meta: {
      title: 'Browse | OrgXenomics'
    }
  }, 
  {
    path: '/browse-by-organ',
    name: 'Browse by Organ',
    component: () => import(/* webpackChunkName: "about" */ '../views/BrowseOrgan.vue'),
    meta: {
      title: 'Browse by Organ| OrgXenomics'
    }
  },
  {
    path: '/citation',
    name: 'citation',
    component: () => import(/* webpackChunkName: "about" */ '../views/CitationPage.vue'),
    meta: {
      title: 'Citation Guidelines for SDAC | SDAC'
    }
  },




  {
    path: '/update-data-SR',
    name: 'update-data-SR',
    component: () => import(/* webpackChunkName: "about" */ '../views/UpdateDate.vue'),
    meta: {
      title: 'Update Data | OrgXenomics'
    }
  },
  {
    path: '/to-be-available',
    name: 'to-be-available',
    component: () => import(/* webpackChunkName: "about" */ '../views/ToBeAvailable.vue'),
    meta: {
      title: 'To be available | OrgXenomics'
    }
  },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
