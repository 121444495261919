<template>
  <div class="main-width">
    <div
      style="
        display: flex;
        justify-content: space-around;
        width: 85%;
        margin: 0 auto;
        position: relative;
        top: 188px;
      "
    >
      <div
        @click="activeName = 'project'"
        :class="[
          'tag-normal',
          'tag-project',
          activeName == 'project' ? 'active' : 'deactive',
        ]"
      >
        <div class="tag-icon">
          <i class="icon iconfont icon-project" style="font-size: 37px"></i>
        </div>
        <div class="tag-title">Project</div>
        <div class="tag-icon-hover">
          <i class="fa fa-hand-o-up" aria-hidden="true"></i>
        </div>
      </div>
      <div
        @click="activeName = 'protein'"
        :class="[
          'tag-normal',
          'tag-protein',
          activeName == 'protein' ? 'active' : 'deactive',
        ]"
      >
        <div class="tag-icon">
          <i
            class="icon iconfont icon-a-protein1"
            style="transform: scale(1.3) rotate(-90deg); margin-top: -2px"
          ></i>
        </div>
        <div class="tag-title">Protein</div>
        <div class="tag-icon-hover">
          <i class="fa fa-hand-o-up" aria-hidden="true"></i>
        </div>
      </div>
    </div>

    <div v-show="activeName == 'project'" style="margin-top: -85px">
      <div class="project-search first-search">
        <div style="display: flex; flex-direction: column; margin-bottom: 40px">
          <div>
            <p
              class="project-search-color"
              style="font-weight: bold; margin-left: 70px; font-size: 22px"
            >
              Search for Organoid/xenograft-based Proteomic Project in Whole
              Database:
            </p>
          </div>
          <div style="display: flex; margin: 0 auto">
            <el-input
              v-model="projectFullText"
              placeholder="Enter the keyword"
              style="width: 900px; font-size: 20px"
              class="project-input"
            >
            </el-input>
            <el-button
              class="btn-submit project-btn"
              @click="submitProjectFullText()"
              style="font-size: 22px; width: 120px"
              >Search</el-button
            >
          </div>
          <p style="margin-left: 70px; font-size: 18px">
            Example:
            <a
              href="/search-project?api=fullText&keyword=Brain"
              hreflang="en"
              class="project-search-color"
              >Brain</a
            >;
            <a
              href="/search-project?api=fullText&keyword=Intestine"
              hreflang="en"
              class="project-search-color"
              >Intestine</a
            >;
            <a
              href="/search-project?api=fullText&keyword=Lung Cancer"
              hreflang="en"
              class="project-search-color"
              >Lung Cancer</a
            >
            ...
          </p>
        </div>
      </div>
    </div>
    <div v-show="activeName == 'protein'" style="margin-top: -85px">
      <div class="project-search first-search">
        <div style="display: flex; flex-direction: column; margin-bottom: 40px">
          <div>
            <p
              class="protein-search-color"
              style="font-weight: bold; margin-left: 70px; font-size: 22px"
            >
              Search for Proteins in Whole
              Database:
            </p>
          </div>
          <div style="display: flex; margin: 0 auto">
            <el-input
              v-model="proteinFullText"
              placeholder="Enter the keyword"
              style="width: 900px; font-size: 20px"
              class="protein-input"
            >
            </el-input>
            <el-button
              class="btn-submit protein-btn"
              @click="submitProteinFullText()"
              style="font-size: 22px; width: 120px"
              >Search</el-button
            >
          </div>
          <p style="margin-left: 70px; font-size: 18px">
            Example:
            <a
              href="/search-protein?api=fullText&keyword=EGFR"
              hreflang="en"
              class="protein-search-color"
              >EGFR</a
            >;
            <a
              href="/search-protein?api=fullText&keyword=Angiotensin-converting enzyme"
              hreflang="en"
              class="protein-search-color"
              >Angiotensin-converting enzyme</a
            >;
            <a
              href="/search-protein?api=fullText&keyword=CAH2_HUMAN"
              hreflang="en"
              class="protein-search-color"
              >CAH2_HUMAN</a
            >

            ...
          </p>
        </div>
      </div>
    </div>

    <div class="bioClass-part" style="margin-top: 50px; padding-top: 20px">
      <HomeCarousel></HomeCarousel>
    </div>

    <div v-show="activeName == 'project'">
      <div class="project-search organoid-search" style="margin-top: 50px">
        <div style="display: flex; flex-direction: column; margin-bottom: 40px">
          <div>
            <p
              style="font-weight: bold; margin-left: 70px; font-size: 22px"
              class="organoid-search-color"
            >
              Search for Organoid-based Proteomic Project in Whole Database:
            </p>
          </div>
          <div style="display: flex; margin: 0 auto">
            <el-input
              v-model="organoidFullText"
              placeholder="Enter the keyword"
              style="width: 900px; font-size: 20px"
            >
            </el-input>
            <el-button
              class="btn-submit organoid-btn"
              @click="submitOrganoidFullText()"
              style="font-size: 22px; width: 120px"
              >Search</el-button
            >
          </div>
          <p style="margin-left: 70px; font-size: 18px">
            Example:
            <a
              href="/search-organoid?api=fullText&keyword=Intestine"
              hreflang="en"
              class="organoid-search-color"
              >Intestine</a
            >;
            <a
              href="/search-organoid?api=fullText&keyword=Kidney cancer"
              hreflang="en"
              class="organoid-search-color"
              >Kidney cancer</a
            >
            ...
          </p>
        </div>

        <div style="display: flex; flex-direction: column; margin-bottom: 50px">
          <div>
            <p
              style="font-weight: bold; margin-left: 70px; font-size: 22px"
              class="organoid-search-color"
            >
              Search for Organoid-based Proteomic Project by Organ/tissue Type:
            </p>
          </div>

          <div style="display: flex; margin: 0 auto">
            <el-select
              v-loading="options_check"
              v-model="organoid_type"
              filterable
              default-first-option
              placeholder="Please select an organ/tissue type"
              style="width: 900px"
            >
              <el-option
                v-for="(item, index) in organoid_type_options"
                :key="index"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-button
              class="btn-submit organoid-btn"
              @click="submitOrganoidTypeList()"
              style="font-size: 22px; width: 120px"
              >Search</el-button
            >
          </div>
        </div>

        <div style="display: flex; flex-direction: column; margin-bottom: 50px">
          <div>
            <p
              style="font-weight: bold; margin-left: 70px; font-size: 22px"
              class="organoid-search-color"
            >
              Search for Organoid-based Proteomic Project by Disease Class:
            </p>
          </div>

          <div style="display: flex; margin: 0 auto">
            <el-select
              v-loading="options_check"
              v-model="organoid_disease"
              filterable
              default-first-option
              placeholder="Please select a disease class"
              style="width: 900px"
            >
              <el-option
                v-for="(item, index) in organoid_disease_options"
                :key="index"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-button
              class="btn-submit organoid-btn"
              @click="submitOrganoidDiseaseList()"
              style="font-size: 22px; width: 120px"
              >Search</el-button
            >
          </div>
        </div>

        <div style="display: flex; flex-direction: column; margin-bottom: 50px">
          <div>
            <p
              style="font-weight: bold; margin-left: 70px; font-size: 22px"
              class="organoid-search-color"
            >
              Search for Organoid-based Proteomic Project by Organoid Source:
            </p>
          </div>

          <div style="display: flex; margin: 0 auto">
            <el-select
              v-loading="options_check"
              v-model="organoid_source"
              filterable
              default-first-option
              placeholder="Please select an organoid source"
              style="width: 900px"
            >
              <el-option
                v-for="(item, index) in organoid_source_options"
                :key="index"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-button
              class="btn-submit organoid-btn"
              @click="submitOrganoidSourceList()"
              style="font-size: 22px; width: 120px"
              >Search</el-button
            >
          </div>
        </div>
      </div>

      <div class="project-search pdx-search" style="margin-top: 50px">
        <div style="display: flex; flex-direction: column; margin-bottom: 40px">
          <div>
            <p
              style="font-weight: bold; margin-left: 70px; font-size: 22px"
              class="pdx-search-color"
            >
              Search for Xenograft-based Proteomic Project in Whole Database:
            </p>
          </div>
          <div style="display: flex; margin: 0 auto">
            <el-input
              v-model="pdxFullText"
              placeholder="Enter the keyword"
              style="width: 900px; font-size: 20px"
              class="pdx-input"
            >
            </el-input>
            <el-button
              class="btn-submit pdx-btn"
              @click="submitPDXFullText()"
              style="font-size: 22px; width: 120px"
              >Search</el-button
            >
          </div>
          <p style="margin-left: 70px; font-size: 18px">
            Example:
            <a
              href="/search-xenograft?api=fullText&keyword=Lung Cancer"
              hreflang="en"
              class="pdx-search-color"
              >Lung Cancer</a
            >;
            <a
              href="/search-xenograft?api=fullText&keyword=Prostate"
              hreflang="en"
              class="pdx-search-color"
              >Prostate</a
            >
            ...
          </p>
        </div>

        <div style="display: flex; flex-direction: column; margin-bottom: 50px">
          <div>
            <p
              style="font-weight: bold; margin-left: 70px; font-size: 22px"
              class="pdx-search-color"
            >
              Search for Xenograft-based Proteomic Project by Organ/tissue Type:
            </p>
          </div>

          <div style="display: flex; margin: 0 auto">
            <el-select
              v-loading="options_check"
              v-model="pdx_organ"
              filterable
              default-first-option
              placeholder="Please select an organ/tissue type"
              style="width: 900px"
              class="pdx-input"
            >
              <el-option
                v-for="(item, index) in pdx_organ_options"
                :key="index"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-button
              class="btn-submit pdx-btn"
              @click="submitPDXOrganList()"
              style="font-size: 22px; width: 120px"
              >Search</el-button
            >
          </div>
        </div>

        <div style="display: flex; flex-direction: column; margin-bottom: 50px">
          <div>
            <p
              style="font-weight: bold; margin-left: 70px; font-size: 22px"
              class="pdx-search-color"
            >
              Search for Xenograft-based Proteomic Project by Disease Class:
            </p>
          </div>

          <div style="display: flex; margin: 0 auto">
            <el-select
              v-loading="options_check"
              v-model="pdx_disease"
              filterable
              default-first-option
              placeholder="Please select a disease class"
              style="width: 900px"
              class="pdx-input"
            >
              <el-option
                v-for="(item, index) in pdx_disease_options"
                :key="index"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-button
              class="btn-submit pdx-btn"
              @click="submitPDXDiseaseList()"
              style="font-size: 22px; width: 120px"
              >Search</el-button
            >
          </div>
        </div>
        <div style="display: flex; flex-direction: column; margin-bottom: 50px">
          <div>
            <p
              style="font-weight: bold; margin-left: 70px; font-size: 22px"
              class="pdx-search-color"
            >
              Search for Xenograft-based Proteomic Project by Xenograft Source:
            </p>
          </div>

          <div style="display: flex; margin: 0 auto">
            <el-select
              v-loading="options_check"
              v-model="pdx_source"
              filterable
              default-first-option
              placeholder="Please select an xenograft source"
              style="width: 900px"
              class="pdx-input"
            >
              <el-option
                v-for="(item, index) in pdx_source_options"
                :key="index"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-button
              class="btn-submit pdx-btn"
              @click="submitPDXSourceList()"
              style="font-size: 22px; width: 120px"
              >Search</el-button
            >
          </div>
        </div>
      </div>
      <div style="height: 20px"></div>
    </div>

    <div v-show="activeName == 'protein'">

      <div class="project-search protein-search" style="margin-top: 50px">
        <div style="display: flex; flex-direction: column; margin-bottom: 50px">
          <div>
            <p
              style="font-weight: bold; margin-left: 70px; font-size: 22px"
              class="protein-search-color"
            >
              Search for Proteins by Sequence Similarity: (
              <span @click="fastaExample('example1')" class="input_example"
                ># Example1</span
              >,
              <span @click="fastaExample('example2')" class="input_example">
                # Example2</span
              >,
              <span @click="fastaExample('example3')" class="input_example"
                ># Example3</span
              >
              )
            </p>
          </div>
          <div style="margin-left: 62px">
            <el-input
              type="textarea"
              v-model="seq_text"
              rows="6"
              style="width: 1022px"
              class="protein-input"
            ></el-input>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-evenly;
              margin-top: 20px;
            "
          >
            <el-button
              class="btn-submit protein-btn"
              @click="fastaReset()"
              style="font-size: 22px; width: 120px; border-radius: 10px"
              >Reset</el-button
            >
            <el-button
              class="btn-submit protein-btn"
              @click="fastaSubmit()"
              style="font-size: 22px; width: 120px; border-radius: 10px"
              >Submit</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="bioClass-part">
      <p style="font-weight: bold; margin-bottom: 20px">About OrgXenomics:</p>

      <p style="margin-bottom: 20px">
        OrgXenomics is unique in <br />
        
       &nbsp;&nbsp;&nbsp;&nbsp; (<span
          style="font-weight: bold; font-style: italic;"
          >a</span
        >) comprehensively offering the raw data for organoid/xenograft-based
        proteomic studies of various diseases; <br />
        
        &nbsp;&nbsp;&nbsp;&nbsp;(<span
          style="font-weight: bold; font-style: italic;"
          >b</span
        >) explicitly describing the establishment details for the models of
        diverse tissues/organs; <br />
        
        &nbsp;&nbsp;&nbsp;&nbsp;(<span
          style="font-weight: bold; font-style: italic;"
          >c</span
        >) systematically providing the proteomic profiles
        (expression/function/interactions) for all proteins in each study.
        <br />Since these models are found to be capable of recapitulating the
        key features of disease diversity, OrgXenomics is expected to have great
        implications for the future practice of translational research and
        precision medicine.
      </p>
    </div> -->
  </div>
</template>

<script>
import HomeCarousel from "@/components/HomeCarousel.vue";
// import * as echarts from "echarts";
import api from "../api/index";
import pathUrl from "../api/path";
export default {
  name: "HomeView",
  data() {
    return {
      activeName: "project",
      localUrl: pathUrl.localUrl,
      seq_text: "",

      projectFullText: "",
      organoidFullText: "",
      pdxFullText: "",
      options_check: true,
      organoid_type_options: [],
      organoid_type: "",
      organoid_disease_options: [],
      organoid_disease: "",
      organoid_source_options: [],
      organoid_source: "",
      pdx_source_options: [],
      pdx_source: "",
      pdx_organ_options: [],
      pdx_organ: "",
      pdx_disease_options: [],
      pdx_disease: "",

      proteinFullText: "",

      protein_family: "",
      protein_familys_check: true,
      protein_names_check: false,
      protein_name: "",
      protein_family_options: [],
      protein_name_options: [],
    };
  },
  components: {
    HomeCarousel,
  },

  methods: {
    fastaExample(example) {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", `${example}.fasta`, false);
      xhr.overrideMimeType("text/html;charset=utf-8");
      xhr.send(null);
      this.seq_text = xhr.responseText;
    },
    fastaReset() {
      this.seq_text = "";
    },
    fastaSubmit() {
      var mission_id = "" + new Date().getTime();
      var post_data = {
        seq_text: this.seq_text,
        mission_id: mission_id,
      };
      console.log(post_data);
      if (this.seq_text.trim() == "") {
        this.$alert("Please enter the correct protein sequence", "Error", {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Close",
        });
      } else {
        api.postSequence(post_data).then((res) => {
          console.log(res);
        });
        this.$router.push("/protein-similarity?jobID=" + mission_id);
      }
    },
    submitProjectFullText() {
      this.$router.push({
        path: `/search-project`,
        query: {
          api: "fullText",
          keyword: this.projectFullText,
        },
      });
    },
    submitOrganoidFullText() {
      this.$router.push({
        path: `/search-organoid`,
        query: {
          api: "fullText",
          keyword: this.organoidFullText,
        },
      });
    },
    submitOrganoidTypeList() {
      this.$router.push({
        path: `/search-organoid`,
        query: {
          api: "byOrganoidType",
          keyword: this.organoid_type,
        },
      });
    },
    submitOrganoidDiseaseList() {
      this.$router.push({
        path: `/search-organoid`,
        query: {
          api: "byOrganoidDisease",
          keyword: this.organoid_disease,
        },
      });
    },
    submitOrganoidSourceList() {
      this.$router.push({
        path: `/search-organoid`,
        query: {
          api: "byOrganoidSource",
          keyword: this.organoid_source,
        },
      });
    },

    submitPDXFullText() {
      this.$router.push({
        path: `/search-xenograft`,
        query: {
          api: "fullText",
          keyword: this.pdxFullText,
        },
      });
    },
    submitPDXSourceList() {
      this.$router.push({
        path: `/search-xenograft`,
        query: {
          api: "PDXSource",
          keyword: this.pdx_source,
        },
      });
    },
    submitPDXOrganList() {
      this.$router.push({
        path: `/search-xenograft`,
        query: {
          api: "byPDXOrgan",
          keyword: this.pdx_organ,
        },
      });
    },
    submitPDXDiseaseList() {
      this.$router.push({
        path: `/search-xenograft`,
        query: {
          api: "byPDXDisease",
          keyword: this.pdx_disease,
        },
      });
    },

    submitProteinFullText() {
      this.$router.push({
        path: `/search-protein`,
        query: {
          api: "fullText",
          keyword: this.proteinFullText,
        },
      });
    },

    resetFamilyProtein() {
      this.protein_family = "";
      this.protein_name = "";
    },
    submitFamilyProtein() {
      this.$router.push({
        path: `/search-protein`,
        query: {
          api: "byFamilyProtein",
          keyword: this.protein_name,
        },
      });
    },
  },

  beforeMount() {},
  mounted() {
    api.getSearchProjectList().then((res) => {
      this.options_check = false;
      this.organoid_type_options = res.data.organoid_type_options;
      this.organoid_disease_options = res.data.organoid_disease_options;
      this.organoid_source_options = res.data.organoid_source_options;
      this.pdx_source_options = res.data.pdx_source_options;
      this.pdx_organ_options = res.data.pdx_organ_options;
      this.pdx_disease_options = res.data.pdx_disease_options;
    });

    api.getSearchProteinList().then((res) => {
      this.protein_familys_check = false;
      this.protein_family_options = res.data.protein_family_options;
    });
  },

  watch: {
    protein_family: {
      handler: function () {
        this.protein_names_check = true;
        this.protein_name = "";
        api
          .postFamilyProteinList({
            data: this.protein_family,
          })
          .then((res) => {
            this.protein_names_check = false;
            this.protein_name_options = res.data.protein_name_options;
          });
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
@select-disease-color: #f1f1f1;

.tag-normal:hover {
  // .tag-icon {
  //   transform: rotate(360deg);
  //   -webkit-transform: rotate(360deg);
  //   -moz-transform: rotate(360deg);
  //   -o-transform: rotate(360deg);
  //   -ms-transform: rotate(360deg);
  // }
  .tag-icon-hover {
    -webkit-animation: 0.5s rotate-move ease-out forwards;
    animation: 0.5s rotate-move ease-out forwards;
    transform: rotate(0deg);
  }
}

@keyframes rotate-move {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// .tag-icon-hover {
//   transition: All 0.4s ease-in-out;
//   -webkit-transition: All 0.4s ease-in-out;
//   -moz-transition: All 0.4s ease-in-out;
//   -o-transition: All 0.4s ease-in-out;
// }
// .tag-normal:hover {
//   // .tag-icon {
//   //   transform: rotate(360deg);
//   //   -webkit-transform: rotate(360deg);
//   //   -moz-transform: rotate(360deg);
//   //   -o-transform: rotate(360deg);
//   //   -ms-transform: rotate(360deg);
//   // }
//   .tag-icon-hover:hover {
//     transform: rotate(360deg);
//     -webkit-transform: rotate(360deg);
//     -moz-transform: rotate(360deg);
//     -o-transform: rotate(360deg);
//     -ms-transform: rotate(360deg);
//   }
// }
// .tag-normal:hover + .tag-icon-hover {
//   transform: none;
//   -webkit-transform: none;
//   -moz-transform: none;
//   -o-transform: none;
//   -ms-transform: none;
// }

.tag-normal {
  display: flex;
  height: 60px;

  cursor: pointer;
  border-radius: 30px;
  .tag-icon {
    margin: auto 0;
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 25px;
    margin-left: 6px;
    display: flex;
  }
  .tag-icon-hover {
    margin-right: 18px;
    display: flex;
    .fa {
      font-size: 27px;
      margin: auto;
    }
  }
  .tag-title {
    margin: auto;
    font-size: 24px;
    padding-left: 18px;
    padding-right: 24px;
    width: 120px;
    text-align: center;
    font-weight: bold;
    // font-style: italic;
  }
  .icon {
    margin: auto;
    font-size: 27px;
  }
}
.tag-project {
  background: #2c3e50;
  border: 2px solid #2c3e50;
  color: #2c3e50;
  .icon {
    color: #2c3e50;
  }
}
.tag-protein {
  background: #38aba5;
  border: 2px solid #38aba5;
  color: #38aba5;
  .icon {
    color: #38aba5;
  }
}

.deactive {
  background: #eeeeee;
}
.active {
  color: #fff;
}

/deep/.el-tabs__header {
  top: 271px;
}

/deep/.el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/input {
  height: 50px;
  padding-left: 15px;
  font-size: 20px;
  border: 2px solid #0071c1 !important;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  outline: none;
}
/deep/.protein-input input {
  border: 2px solid #38aba5 !important;
}
/deep/.pdx-input input {
  border: 2px solid #e5795c !important;
}
/deep/.organoid-btn {
  background: #0071c1;
}
/deep/.pdx-btn {
  background: #e5795c;
}
/deep/.organoid-btn:hover {
  background: #0071c1ba;
}
/deep/.pdx-btn:hover {
  background: #e5795cba;
}

/deep/.project-input input {
  border: 2px solid #2c3e50 !important;
}
/deep/.project-btn {
  background: #2c3e50;
}
/deep/.project-btn:hover {
  background: #2c3e50ba;
}

/deep/.el-button {
  font-size: 22px !important;
  height: 50px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 2px;
}

/deep/.protein-input input {
  border: 2px solid #38aba5 !important;
}
/deep/.protein-btn {
  background: #38aba5;
}
/deep/.protein-btn:hover {
  background: #38aba5ba;
}
.first-search {
  margin-top: -10px;
  padding-top: 68px;
  padding-bottom: 81px;
}
</style>
