<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div id="app">
    <vue-particles
      color="#dedede"
      :particleOpacity="0.7"
      :particlesNumber="200"
      shapeType="circle"
      :particleSize="10"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="false"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="2"
      :hoverEffect="false"
      hoverMode="bubble"
      :clickEffect="false"
    >
    </vue-particles>
    <el-container style="min-height: 100vh; min-width: 1280px">
      <el-backtop></el-backtop>
      <el-header style="height: auto" class="home-gradient">
        <div style="background-color: #fbfbfb; height: 229px">
          <div
            class="content-width"
            style="width: 1250px; display: flex; justify-content: space-between"
          >
            <div
              class="v-center"
              style="
                height: 225px;
                width: 1250px;
                display: flex;
                margin: 0 auto;
                justify-content: space-between;
                background: url(/pic/OrgXenomics-title.png);
                background-size: contain;
                position: relative;
              "
            >
              <div
                style="
                  position: absolute;
                  cursor: pointer;
                  top: 50px;
                  height: 34px;
                  width: 218px;
                  left: 240px;
                  text-align: right;
                "
                @click="playAudio"
                title="Click to Read OrgXenomics"
              >
                <img
                  src="/pic/play.png"
                  style="display: initial; width: 27px; margin-top: 4px"
                />
                <audio
                  ref="audio"
                  :src="`${localUrl}/files/home-picture/OrgXenomics.mp3`"
                  preload="auto"
                ></audio>
              </div>
            </div>
          </div>


        </div>
        <div style="height: 60px" class="content-width">
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
            "
          >
            <el-menu
              class="el-menu-demo"
              mode="horizontal"
              background-color="#455da500"
              text-color="#fff"
              style="margin: auto 0"
            >
              <el-menu-item>
                <router-link to="/" class="no-line"
                  ><i class="fa fa-home" aria-hidden="true"></i>
                  Home</router-link
                >
              </el-menu-item>

              <el-submenu index="4">
                <template slot="title">
                  <i class="fa fa-search-plus" aria-hidden="true"></i>
                  Search</template
                >
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/search-project-page"
                    class="no-line"
                    style="color: black"
                  >
                    Search for   
                    Projects</router-link
                  >
                </el-menu-item>
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/search-protein-page"
                    class="no-line"
                    style="color: black"
                  >
                    Search for Proteins</router-link
                  >
                </el-menu-item>
              </el-submenu>

              <el-menu-item>
                <router-link to="/browse" class="no-line"
                  ><i class="fa fa-list" aria-hidden="true" style="margin-bottom: 5px;"></i
                  >Browse</router-link
                >
              </el-menu-item>

              <el-menu-item>
                <router-link to="/download" class="no-line"
                  ><i class="fa fa-cloud-download" aria-hidden="true"></i
                  >Download</router-link
                >
              </el-menu-item>


              <!-- <el-menu-item>
                <router-link to="/download-manual" class="no-line"
                  ><i class="fa fa-file-text-o" aria-hidden="true"></i
                  >Manual</router-link
                >
              </el-menu-item> -->

             <el-submenu index="5">
                <template slot="title">
                  <i class="fa fa-file-text-o" aria-hidden="true"></i>
                  Help</template
                >
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/manual"
                    class="no-line"
                    style="color: black"
                  >
                 User Manual</router-link
                  >
                </el-menu-item>
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/download-manual"
                    class="no-line"
                    style="color: black"
                  >
                    Download Tool</router-link
                  >
                </el-menu-item>

              </el-submenu> 

              <el-menu-item>
                <router-link to="/contact" class="no-line"
                  ><i class="fa fa-address-book-o" aria-hidden="true"></i
                  >Contact</router-link
                >
              </el-menu-item>
            </el-menu>
          </div>
        </div>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <br />
      <el-footer id="page-foot" style="height: auto" class="home-gradient">
        <div id="page-foot-row" style="height: 266px">
          <el-row class="content-width" style="display: flex; height: 100%">
            <el-col :span="8">
              <h3 class="foot-title"><b>Cite OrgXenomics</b></h3>
              <div class="foot-content">
                <p>
                  Y. T. Zhang, X. C. Lian, H. W. Xu, S. S. Zhu, H. Zhang, Z. H. Ni, T. T. Fu, S. P. Liu, L. Tao*, Y. Zhou*, F. Zhu*.
                  OrgXenomics: an integrated proteomic knowledge base for patient-derived organoid and xenograft.
                  <a href="https://doi.org/10.1093/nar/gkae861" target="_blank"
                    ><b><i>Nucleic Acids Research</i></b></a
                  >. doi: 10.1093/nar/gkae861 (2024).
                  <!-- <a
                    href="https://www.ncbi.nlm.nih.gov/pubmed/37850638"
                    target="_blank"
                    >PMID: 37850638</a
                  >. -->
                </p>
              </div>
            </el-col>
            <el-col :span="8">
              <h3 class="foot-title"><b>Visitor Map</b></h3>
              <div
                class="foot-content"
                style="text-align: center; padding-top: 18px"
              >
                <iframe
                  style="transform: scale(1.23)"
                  :src="iframe_url"
                  frameborder="0"
                ></iframe>
              </div>
            </el-col>
            <el-col :span="8">
              <h3 class="foot-title"><b>Correspondence</b></h3>
              <div class="foot-content">
                <p style="margin-top: 15px">
                  <b
                    >Prof. Feng Zhu&nbsp;
                    <!-- <a
                      href="https://www.idrblab.org/Peoples-PI.php"
                      target="_blank"
                      style="color: #fff"
                    >
                      <i class="fa fa-external-link" aria-hidden="true"></i></a
                  > -->
                  <a
                      href="https://idrblab.org/zhufeng.php"
                      target="_blank"
                    >
                      <i class="fa fa-external-link" aria-hidden="true"></i></a
                  >
                </b>
                </p>
                <p>
                  College of Pharmaceutical Sciences, Zhejiang University,
                  Hangzhou, China.
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>


<script>
import pathUrl from "./api/path";

export default {
  name: "App",
  data() {
    return {
      localUrl: pathUrl.localUrl,
      isCollapse: false,
      iframe_url: `${pathUrl.localUrl}/files/visitermap`,
      test: ["a", "b"],
    };
  },

  components: {
    // AboutView,
  },
  methods: {
    playAudio() {
      this.$refs.audio.play();
    },
  },
};
</script>


<style lang="less">
// @import '~css-doodle/css-doodle.min.css';

@purple: #455da500;

#app {
  font-family: Harding, "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.content-width {
  width: 1250px;
  margin: 0px auto !important;
}
.el-menu-item:hover,
.el-submenu:hover {
  outline: 0;
  background-color: #bce9f04f !important;
  border-radius: 10px;
}

p {
  margin: 5px 0;
}

h3 {
  margin: 15px 0;
}

.el-menu--horizontal {
  // display: block !important;
  .el-submenu__title {
    border-radius: 10px;
    font-size: 24px;
    padding-right: 0;
  }
  .el-submenu__title:hover {
    background-color: #bce9f04f !important;
  }
  .el-submenu__icon-arrow {
    margin-top: 6px !important;
  }
  .el-menu-item,
  .el-submenu {
    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;
    padding: 0px;
    // margin: 0 52px 0 0;
  }
  .el-menu {
    .el-menu-item {
      height: 60px !important;
      padding: 10px !important;
    }
  }
}

.el-menu--popup {
  background: #fff !important;
  border-radius: 5px !important;
  padding: 5px 0 !important;
  border: 1px dashed #85858552 !important;
  box-shadow: 4px 5px 6px 0px #a1a1a1 !important;
}
.el-header,
.el-main,
.el-footer {
  padding: 0 !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0px !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

body {
  display: block;
  margin: 0;
  line-height: 1.42857;
  font-size: 20px;
}

.foot-title {
  color: #ffffff;
  border-bottom: 3px solid #fff;
  font-size: 24px;
  text-align: left;
  padding-top: 10px;
}

.foot-content {
  color: #ffffff;
  text-align: left;
  font-size: 16px;
  line-height: 1.6;
}

.el-row {
  margin: 0 3%;
  margin-bottom: 20px;
}

.el-col {
  padding: 0 20px;
}

.el-menu-item,
.el-submenu {
  i {
    color: #ffffff !important;
    margin-right: 10px;
    font-size: 24px !important;
    margin-bottom: 8px;
  }
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 2px solid #409eff00 !important;
  color: #ffffff !important;
}

.no-line {
  text-decoration: none !important;
  padding: 17px;
}

nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
  }
  a.router-link-exact-active {
    color: #42b983;
  }
}
.el-main {
  overflow: hidden;
}
.el-button,
.fs20,
.el-tag,
.el-message-box__content,
.el-upload-list__item,
.el-notification__content,
.el-select-dropdown__item,
.el-input--suffix .el-input__inner,
.el-input {
  font-size: 20px !important;
}
.el-backtop {
  box-shadow: 0 0 6px 1px #03a9f4 !important;
}
.home-gradient {
  background: #2c3e50;
}

.el-loading-mask {
  border-radius: 10px;
}

.submenu-item {
  background: #fff !important;
  color: #000 !important;
  border-radius: 0 !important;
  font-size: 20px !important;
}
.submenu-item:hover {
  background: #ff584c30 !important;
}
</style>

