<template>
  <div>
    <p style="font-size: 26px; font-weight: bold">About OrgXenomics</p>
    <el-carousel
      :interval="3000"
      arrow="always"
      height="1050px"
      style="margin-top: 20px"
    >
      <el-carousel-item>
        <div>
          <img
            src="/pic/OrgXenomics-Graphic Abstract.png"
            style="display: initial; width: 100%"
          />
          <br /><br />

          <p>
            Patient-derived models (PDMs, particularly organoids and xenografts)
            are irreplaceable tools for precision medicine, from target
            development to lead identification, then to preclinical evaluation,
            and finally to clinical decision-making. So far, PDM-based
            proteomics has emerged to be one of the cutting-edge directions and
            massive data have been accumulated. However, such PDM-based
            proteomic data have not been provided by any of the available
            databases, and proteomics profiles of all proteins in proteomic
            study are also completely absent from existing databases. Herein, an
            integrated database named '<i><b>OrgXenomics</b></i
            >' was thus developed to provide the proteomic data for PDMs, which
            was unique in:
          </p>
<br>
          <p style="padding-left: 30px">
            (<i><b>a</b></i
            >) Explicitly describing the establishment details for a wide array of tissue/organ models,
          </p>
          <p style="padding-left: 30px">
            (<i><b>b</b></i
            >) Systematically providing the proteomic profile for all proteins in one proteomic study,
          </p>
          <p style="padding-left: 30px">
            (<i><b>c</b></i
            >) Comprehensively illustrating the raw data for diverse PDM-based proteomic analyses.
          </p>
<br>
          <p>
            Our <i><b>OrgXenomics</b></i> was expected to server as one good
            complement to existing proteomic databases, and had great
            implication for promoting the future practice of precision medicine.
          </p>
        </div>
      </el-carousel-item>

      <el-carousel-item>
        <div style="text-align: center">
          <img src="/pic/OrgXenomics-Figure 1.png" style="width: 84%" />
        </div>
        <p>
          Three key features of <i>OrgXenomics</i> and the implications of
          organoid/xenograft in drug developments (including target discovery,
          lead identification, preclinical evaluation, and clinical
          decision-making, and so on). <i>OrgXenomics</i> was unique in
          explicitly describing the establishment details for the models of
          diverse tissue/organ, systematically providing proteomics profiles
          (such as expression, function, and interaction) for all proteins in
          proteomic study, and comprehensively giving the raw data for
          organoid/xenograft-based proteomic studies of various diseases.
        </p>
      </el-carousel-item>
      <el-carousel-item>
        <div style="text-align: center">
          <img src="/pic/OrgXenomics-Figure 2.png" style="width: 73%" />
        </div>
        <p>
          The explicit establishment details of organoids/xenografts proteomics
          projects together with their affiliated project information. For each
          organoid proteomics project, the organoid type, organoid source, and
          culture protocols were recorded; for a studied xenograft proteomics
          project, the xenograft source, implanted species, and implanted
          location were documented. The affiliated project information included
          studied disease, organism, quantification methods, instrument, etc.
        </p>
      </el-carousel-item>
      <el-carousel-item>
        <div style="text-align: center">
          <img src="/pic/OrgXenomics-Figure 3.png" style="width: 77%" />
        </div>
        <p>
          The proteomic profiles for all proteins in each project. (<i
            ><b>a</b></i
          >) expression profiles for PDM-proteomic project. The volcano plot was
          provided to depict different expression levels of proteins between two
          groups; the heatmap was illustrated to analyze the pattern of
          differentially expressed proteins between two groups; the boxplot
          displaying the protein expressions in all studied groups was plotted
          to enable the direct comparison of expressions among studied groups,
          especially for the project of over two groups. (<i><b>b</b></i
          >) function and interaction profiles for PDM-proteomics projects. The
          bubble chart was plotted to describe top 15 most significantly
          enriched terms and the protein-protein interaction network described
          the interactions among differentially expressed proteins.
        </p>
      </el-carousel-item>
      <el-carousel-item>
        <div style="text-align: center">
          <img src="/pic/OrgXenomics-Figure 4.png" style="width: 61%" />
        </div>
        <p>
          The functions of search, browse and download. (<i><b>a</b></i
          >) multiple strategies for projects and proteins search. For project
          search, user can use keyword search or select the option in dropdown
          menu. For protein search, user can input keyword or protein sequence
          to obtain a list of sequence-similar proteins based on the result of
          BLAST. (<i><b>b</b></i
          >) convenient browse function. Users can browse the projects based on
          tissue type/disease class, which helps the user to access and filter
          the project information. (<i><b>c</b></i
          >) diverse data download functions. In project page, users can
          download individual file using browser or download all files of a
          project using batch download tools. In the Download page, user can
          download the general information and proteomic profile for all
          projects in bulk.
        </p>
      </el-carousel-item>
      <el-carousel-item>
        <div style="text-align: center">
          <img src="/pic/OrgXenomics-Figure 5.png" style="width: 63%" />
        </div>
        <p>
          A typical page providing the diverse information of a protein. (<i
            ><b>a</b></i
          >) Bbasic information of each protein. A wealth of basic information
          for protein was provided in the upper section. (<i><b>b</b></i
          >) list of proteomic projects in which the protein was differentially
          expressed. A list of projects in which a protein was differentially
          expressed was given accompanied with the log2FC and p-value. User can
          click the button of Project Info to explore detailed information of
          corresponding projects. (<i><b>c</b></i
          >) PI network and tissue-specific expression of proteins. PPI network
          was collected from STRING with a confidence score >0.9. The source of
          data for tissue-specific RNA and protein expressions level were from
          Jiang's study (<i>Cell</i>. 183: 269-283, 2020), which quantified proteins across 32 normal human
          tissues.
        </p>
      </el-carousel-item>
      <el-carousel-item>
        <div style="text-align: center">
          <img src="/pic/OrgXenomics-Figure 6.png" style="width: 62%" />
        </div>
        <p>
          Case studies of organoid/xenograft proteomic datasets. (<i><b>a</b></i
          >) case study of schizophrenia dataset. The left panel described the
          experimental details of one organoid dataset (ORG8301292), and the
          right panel showed the differential expression of cytochrome c oxidase
          subunit 2 (COX2) identified using this organoid dataset. These
          reproduced the result of the previous study (<i>Prog Neuropsychopharmacol Biol Psychiatry</i>. 79: 481-492, 2017) that reported the
          overexpression of COX2 in the brains of schizophrenia patients
          comparing with that of healthy people. (<i><b>b</b></i
          >) case study of lung cancer dataset. The left panel provided the
          experimental details of one xenograft dataset (XENO7094992), while the
          right panel illustrated the differential expression of napsin-A among
          three PDX types (lung adenocarcinoma, large cell neuroendocrine
          carcinoma and lung squamous cell carcinoma). The resulting expression
          patterns reproduced that of previous publication (<i>Transl Lung Cancer Res</i>. 10: 766-775, 2021) which reported the
          overexpression of napsin-A in the tissues of lung adenocarcinoma
          patients comparing with that of lung squamous cell carcinoma patients.
        </p>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
</style>
  